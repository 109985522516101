import type { VerticalNavItems } from '~~/types'

const nav: VerticalNavItems = [
  { heading: 'private-menu.My Catalog', action: 'manage', subject: 'catalog' },

  {
    title: 'private-menu.My Packs',
    icon: { icon: 'mdi-package-variant' },
    to: 'private-catalog-packs-list',
    action: 'manage',
    subject: 'catalog',
  },
  {
    title: 'private-menu.My Instruments',
    icon: { icon: 'mdi-piano' },
    to: 'private-catalog-instruments-list',
    action: 'manage',
    subject: 'catalog',
  },
  {
    title: 'private-menu.My Sales',
    icon: { icon: 'mdi:hand-coin-outline' },
    to: 'private-user-sales',
    action: 'read',
    subject: 'sales',
  },
]

export default nav
