<script lang="ts" setup>
import type { NavSectionTitle } from '~~/types/dvn-layouts'

const props = defineProps<{
  item: NavSectionTitle
}>()

const { $themeConfig } = useNuxtApp()
const { isVerticalNavMini, dynamicI18nProps } = useThemeConfig()
const { can } = useAppAuthorizations()
const { width: windowWidth } = useWindowSize()
const shallRenderIcon = isVerticalNavMini(windowWidth)
</script>

<template>
  <li v-if="can(props.item.action, props.item.subject)" class="nav-section-title">
    <div class="title-wrapper">
      <Transition name="vertical-nav-section-title" mode="out-in">
        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
        <Component
          :is="
            shallRenderIcon
              ? $themeConfig.app.iconRenderer
              : $themeConfig.app.enableI18n
                ? 'i18n-t'
                : 'span'
          "
          :key="shallRenderIcon"
          :class="shallRenderIcon ? 'placeholder-icon' : 'title-text'"
          v-bind="{
            ...$themeConfig.icons.sectionTitlePlaceholder,
            ...dynamicI18nProps(item.heading, 'span'),
          }"
        >
          {{ !shallRenderIcon ? item.heading : null }}
        </Component>
        <!-- eslint-enable vue/no-v-text-v-html-on-component -->
      </Transition>
    </div>
  </li>
</template>
