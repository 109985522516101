import type { NavGroup } from '~~/types'

export const useLayout = () => {
  /**
   * return runtime config value
   */
  // const { enableAuthorisations } = useRuntimeConfig().public
  const { can } = useAppAuthorizations()

  /**
   * Check if user can view item based on it's ability
   * Based on item's action and subject & Hide group if all of it's children are hidden
   * @param {Object} item navigation object item
   */
  const canViewNavMenuGroup = (item: NavGroup) => {
    // if (!enableAuthorisations) return true
    const hasAnyVisibleChild = item.children.some((i) => can(i.action, i.subject))

    // If subject and action is defined in item => Return based on children visibility (Hide group if no child is visible)
    // Else check for ability using provided subject and action along with checking if has any visible child
    if (!(item.action && item.subject)) return hasAnyVisibleChild

    return can(item.action, item.subject) && hasAnyVisibleChild
  }

  return {
    canViewNavMenuGroup,
  }
}
