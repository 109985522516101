<script setup lang="ts">
const props = defineProps<{
  redirect?: string
}>()

const errorLogger = (error: any) => {
  // console.error(error.constructor.name)
  // console.error(error.statusCode)
  // console.error(error.statusMessage)
  // console.error(error.message)
}

async function closeError(error: any) {
  await clearError({ redirect: props.redirect ? props.redirect : '/' })
  error.value = null
}
</script>

<template>
  <NuxtErrorBoundary @error="errorLogger">
    <slot />

    <template #error="{ error }">
      <VDialog v-if="error" model-value max-width="50%" class="text-subtitle-2">
        <VCard>
          <VCardTitle class="text-error text-h5 text-center"
            ><VIcon icon="mdi-alert-circle" size="x-large"></VIcon><br />
            {{ $t('ERROR') }}
          </VCardTitle>
          <VCardText class="text-error">
            <b>constructor:</b> {{ error.value.constructor.name }}
            <br />
            <b>statusCode:</b> {{ error.value.statusCode }}
            <br />
            <b>statusMessage:</b> {{ error.value.statusMessage }}
            <br />
            <b>message:</b> {{ error.value.message }}
          </VCardText>
          <VCardActions>
            <VBtn color="error" size="large" block variant="outlined" @click="closeError(error)">
              Ok
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>
  </NuxtErrorBoundary>
</template>
