<script lang="ts" setup>
import HorizontalNavLink from './HorizontalNavLink.vue'
import HorizontalNavGroup from './HorizontalNavGroup.vue'
import type { NavGroup } from '~~/types/dvn-layouts'
import { isNavGroupActive } from '#imports'

interface Props {
  item: NavGroup
  childrenAtEnd?: boolean

  // ℹ️ We haven't added this prop in vertical nav because we don't need such differentiation in vertical nav for styling
  isSubItem?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  childrenAtEnd: false,
  isSubItem: false,
})

const { $themeConfig } = useNuxtApp()
const { dynamicI18nProps, isAppRtl } = useThemeConfig()
const { canViewNavMenuGroup } = useLayout()
const route = useRoute()
const router = useRouter()
const isGroupActive = ref(false)

/*
  Watch for route changes, more specifically route path. Do note that this won't trigger if route's query is updated.

  updates isActive & isOpen based on active state of group.
*/
watch(
  () => route.path,
  () => {
    const isActive = isNavGroupActive(props.item.children, router)
    isGroupActive.value = isActive
  },
  { immediate: true },
)
</script>

<script lang="ts">
export default {
  name: 'LayoutsHorizontalNavGroup',
}
</script>

<template>
  <LayoutsHorizontalNavPopper
    v-if="canViewNavMenuGroup(item)"
    :is-rtl="isAppRtl"
    class="nav-group"
    tag="li"
    content-container-tag="ul"
    :class="[
      {
        active: isGroupActive,
        'children-at-end': childrenAtEnd,
        'sub-item': isSubItem,
        disabled: item.disable,
      },
    ]"
    :popper-inline-end="childrenAtEnd"
  >
    <div class="nav-group-label">
      <Component
        :is="$themeConfig.app.iconRenderer || 'div'"
        class="nav-item-icon"
        v-bind="item.icon || $themeConfig.verticalNav.defaultNavItemIconProps"
      />
      <Component
        :is="$themeConfig.app.enableI18n ? 'i18n-t' : 'span'"
        v-bind="dynamicI18nProps(item.title, 'span')"
        class="nav-item-title"
      >
        {{ item.title }}
      </Component>
      <Component
        v-bind="$themeConfig.icons.chevronDown"
        :is="$themeConfig.app.iconRenderer || 'div'"
        class="nav-group-arrow"
      />
    </div>

    <template #content>
      <Component
        :is="'children' in child ? HorizontalNavGroup : HorizontalNavLink"
        v-for="child in item.children"
        :key="child.title"
        :item="child"
        children-at-end
        is-sub-item
      />
    </template>
  </LayoutsHorizontalNavPopper>
</template>

<style lang="scss">
.layout-horizontal-nav {
  .nav-group {
    .nav-group-label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .popper-content {
      z-index: 1;

      > div {
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}
</style>
