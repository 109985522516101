<script lang="ts" setup>
import { ContentLayoutNav } from '~~/plugins/layouts/enums'

const { setSeoMeta } = usePageSeoMeta()
setSeoMeta({
  title: 'Desktop',
})

useHead({
  meta: [{ name: 'robots', content: 'noindex, nofollow' }], // noindex, nofollow to avoid indexing by search engines for all private layout pages
})

const {
  appContentLayoutNav,
  switchToVerticalNavOnLtOverlayNavBreakpoint,
  layoutAttrs,
  injectSkinClasses,
} = useThemeConfig()

useAppContext().context = 'private'

onMounted(() => {
  // onMounted or onUpdated will NOT be called during SSR and will only be executed on the client

  // ℹ️ This will switch to vertical nav when define breakpoint is reached when in horizontal nav layout
  // Remove below composable usage if you are not using horizontal nav layout in your app
  const { width: windowWidth } = useWindowSize()
  switchToVerticalNavOnLtOverlayNavBreakpoint(windowWidth)

  // ℹ️ This will inject classes in body tag for accurate styling
  injectSkinClasses()
})
</script>

<template>
  <LayoutsVerticalNavBase
    v-if="appContentLayoutNav === ContentLayoutNav.Vertical"
    v-bind="layoutAttrs"
  />
  <LayoutsHorizontalNavBase v-else v-bind="layoutAttrs" />
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use '@styles/layouts/default-layout';
</style>

<style lang="css">
@import url('@styles/site/index.css');
</style>
