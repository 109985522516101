import type { VerticalNavItems } from '~~/types'

const nav: VerticalNavItems = [
  // {
  //   title: 'private-menu.Home',
  //   icon: { icon: 'mdi-home' },
  //   to: 'private-catalog-home',
  //   action: 'read',
  //   subject: 'catalog',
  // },
  { heading: 'private-menu.Catalog', action: 'manage', subject: 'catalog' },
  {
    title: 'private-menu.Search',
    icon: { icon: 'mdi-magnify' },
    to: 'private-catalog-search',
    action: 'read',
    subject: 'catalog',
  },
  {
    title: 'private-menu.My Favorites',
    icon: { icon: 'mdi-heart-outline' },
    to: 'private-user-favorites',
    action: 'read',
    subject: 'user-profile',
  },
  {
    title: 'private-menu.My Purchases',
    icon: { icon: 'mdi-cart-heart' },
    to: 'private-user-purchases',
    action: 'read',
    subject: 'user-profile',
  },
  {
    title: 'private-menu.My Learning',
    icon: { icon: 'mdi-learn-outline' },
    to: 'private-user-learning',
    action: 'manage',
    subject: 'catalog',
  },
]

export default nav
