<script lang="ts" setup>
import type { NavLink } from '~~/types/dvn-layouts'
import { getComputedNavLinkToProp, isNavLinkActive } from '#imports'

interface Props {
  item: NavLink

  // ℹ️ We haven't added this prop in vertical nav because we don't need such differentiation in vertical nav for styling
  isSubItem?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isSubItem: false,
})

const { $themeConfig } = useNuxtApp()
const { dynamicI18nProps } = useThemeConfig()
const { can } = useAppAuthorizations()
</script>

<template>
  <li
    v-if="can(item.action, item.subject)"
    class="nav-link"
    :class="[
      {
        'sub-item': props.isSubItem,
        disabled: item.disable,
      },
    ]"
  >
    <Component
      :is="item.to ? 'RouterLink' : 'a'"
      v-bind="getComputedNavLinkToProp(item)"
      :class="{ 'router-link-active router-link-exact-active': isNavLinkActive(item, $router) }"
    >
      <Component
        :is="$themeConfig.app.iconRenderer || 'div'"
        class="nav-item-icon"
        v-bind="item.icon || $themeConfig.verticalNav.defaultNavItemIconProps"
      />
      <Component
        :is="$themeConfig.app.enableI18n ? 'i18n-t' : 'span'"
        class="nav-item-title"
        v-bind="dynamicI18nProps(item.title, 'span')"
      >
        {{ item.title }}
      </Component>
    </Component>
  </li>
</template>

<style lang="scss">
.layout-horizontal-nav {
  .nav-link a {
    display: flex;
    align-items: center;
  }
}
</style>
