import type { VerticalNavItems } from '~~/types'

const nav: VerticalNavItems = [
  { heading: 'private-menu.Administration', action: 'manage', subject: 'all' },
  {
    title: 'private-menu.Packs',
    icon: { icon: 'mdi-package-variant' },
    to: 'private-admin-packs',
    action: 'manage',
    subject: 'all',
  },
  // {
  //   title: 'private-menu.DrumKits',
  //   icon: { icon: 'mdi-apps' },
  //   to: 'private-admin-drumkits',
  //   action: 'manage',
  //   subject: 'all',
  // },
  {
    title: 'private-menu.Instruments',
    icon: { icon: 'mdi-piano' },
    to: 'private-admin-instruments',
    action: 'manage',
    subject: 'all',
  },
  // {
  //   title: 'private-menu.LoopKits',
  //   icon: { icon: 'mdi-autorenew' },
  //   to: 'private-admin-loopkits',
  //   action: 'manage',
  //   subject: 'all',
  // },
  // {
  //   title: 'private-menu.MidiFiles',
  //   icon: { icon: 'mdi-midi' },
  //   to: 'private-admin-midifiles',
  //   action: 'manage',
  //   subject: 'all',
  // },
  {
    title: 'private-menu.Videos',
    icon: { icon: 'mdi-youtube' },
    to: 'private-admin-videos',
    action: 'manage',
    subject: 'all',
  },

  {
    title: 'private-menu.Tags',
    icon: { icon: 'mdi-tag-multiple' },
    to: 'private-admin-tags',
    action: 'manage',
    subject: 'all',
  },
  {
    title: 'private-menu.Users',
    icon: { icon: 'mdi-account-multiple' },
    to: 'private-admin-users',
    action: 'manage',
    subject: 'all',
  },
]

export default nav
