<script lang="ts" setup>
import navCatalogItems from '~~/navigation/vertical/catalog'
import navUserCatalogItems from '~~/navigation/vertical/user-catalog'
import navAdminItems from '~~/navigation/vertical/admin'

const { enableNuxtErrorBoundary } = useRuntimeConfig().public
const { isLessThanOverlayNavBreakpoint } = useThemeConfig()
const { width: windowWidth } = useWindowSize()

const userStore = useUserStore()
const navItems =
  userStore.user?.role === 'admin'
    ? [...navCatalogItems, ...navUserCatalogItems, ...navAdminItems]
    : [...navCatalogItems, ...navUserCatalogItems]
</script>

<template>
  <LayoutsVerticalNavLayout :nav-items="navItems">
    <!-- 👉 navbar -->
    <template #navbar="{ toggleVerticalOverlayNavActive }">
      <div class="d-flex h-100 align-center">
        <VBtn
          v-if="isLessThanOverlayNavBreakpoint(windowWidth)"
          icon
          variant="text"
          color="default"
          class="ms-n3"
          size="small"
          @click="toggleVerticalOverlayNavActive(true)"
        >
          <VIcon icon="mdi-menu" size="1.5em" />
        </VBtn>

        <VSpacer />

        <LayoutsNavbarCart />
        <LayoutsNavbarI18n />
        <LayoutsNavbarThemeSwitcher />
        <!-- <LayoutsNavbarNotifications class="me-2" /> -->
        <LayoutsUserProfileMenu />
      </div>
    </template>

    <!-- 👉 content-loading -->
    <!-- loader is managed in app.vue -->
    <!-- <template #content-loading>
    </template> -->

    <!-- 👉 Pages -->
    <CoreErrorBoundaryDialog v-if="enableNuxtErrorBoundary">
      <NuxtPage />
    </CoreErrorBoundaryDialog>
    <NuxtPage v-else />

    <!-- 👉 Footer -->
    <template #footer>
      <LayoutsFooter />
    </template>
  </LayoutsVerticalNavLayout>
</template>
