<script lang="ts" setup>
import { localeLink } from '#imports'
import navItems from '~~/navigation/horizontal'

const { enableNuxtErrorBoundary } = useRuntimeConfig().public
// const { siteName } = useRuntimeConfig().public
</script>

<template>
  <LayoutsHorizontalNavLayout :nav-items="navItems">
    <!-- 👉 navbar -->
    <template #navbar>
      <NuxtLink
        :to="localeLink('/')"
        target="_blank"
        rel="noopener noreferrer"
        class="d-flex align-center"
      >
        <LayoutsLogo height="1.875em" />
        <!-- <h1 class="font-weight-bold leading-normal text-xl text-uppercase">
          {{ siteName }}
        </h1> -->
        <LayoutsLogoCut height="1.75em" />
      </NuxtLink>
      <VSpacer />

      <LayoutsNavbarCart />
      <LayoutsNavbarI18n />
      <LayoutsNavbarThemeSwitcher />
      <!-- <LayoutsNavbarNotifications class="me-2" /> -->
      <LayoutsUserProfileMenu />
    </template>

    <!-- 👉 content-loading -->
    <!-- loader is managed in app.vue -->
    <!-- <template #content-loading>
    </template> -->

    <!-- 👉 Pages -->
    <CoreErrorBoundaryDialog v-if="enableNuxtErrorBoundary">
      <NuxtPage />
    </CoreErrorBoundaryDialog>
    <NuxtPage v-else />

    <!-- 👉 Footer -->
    <template #footer>
      <LayoutsFooter />
    </template>
  </LayoutsHorizontalNavLayout>
</template>
