<script setup lang="ts">
import { useCycleList } from '@vueuse/core'
import type { ThemeSwitcherTheme } from '~~/types/dvn-layouts'

const userStore = useUserStore()

const props = defineProps<{
  themes: ThemeSwitcherTheme[]
}>()

const { theme } = useThemeConfig()
const {
  state: currentTheme,
  next: getNextThemeName,
  index: currentThemeIndex,
} = useCycleList(
  props.themes.map((t) => t.name),
  { initialValue: theme.value },
)

const getThemeIcon = computedWithControl(theme, () => {
  const nextThemeIndex =
    currentThemeIndex.value + 1 === props.themes.length ? 0 : currentThemeIndex.value + 1
  return props.themes[nextThemeIndex].icon
})

const changeTheme = () => {
  theme.value = getNextThemeName()
  userStore.saveTheme(theme.value as 'light' | 'dark')
}

watchEffect(() => {
  // update theme from user preferences
  if (userStore.user) {
    const privatePreferences = userStore.user?.privatePreferences
    if (privatePreferences && privatePreferences.theme) {
      theme.value = privatePreferences.theme
    }
  }

  // update theme list with current theme selection
  currentTheme.value = theme.value
})
</script>

<template>
  <VBtn icon variant="text" color="default" size="small" @click="changeTheme">
    <VIcon :icon="getThemeIcon" size="1.4em" />
  </VBtn>
</template>
